import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';
import compose from '@nubank/nuds-web/utils/functional/compose';

import { removeQueryString, removeHashFromUrl } from '@nubank/www-latam-commons/utils/urlUtils';
import { localizeRoute, removeTrailingSlash } from 'utils/i18nUtils';

const GROUP_TO_PUT_SLASH = /\/?(\?|#|$)/;

const addSlashAtEnd = href => (
  `${href.replace(GROUP_TO_PUT_SLASH, '/$1')}`
);

const getPathWithoutSuffix = compose(
  removeTrailingSlash,
  removeHashFromUrl,
  removeQueryString,
);

const RouterLink = forwardRef((props, ref) => {
  const intl = useIntl();
  const router = useRouter();
  const {
    activeClassName,
    children,
    className,
    href,
    linkComponent: Link,
    forceRefresh,
    locale,
    routerLinkComponent: RouterLinkComponent,
    ...rest
  } = props;

  const normalizedHref = getPathWithoutSuffix(href);

  const localizedHref = localizeRoute(href, locale || intl.locale);
  const currentRoute = localizeRoute(router.pathname, intl.locale);
  const canonicalRoute = router.query?.canonicals
    && router.query.canonicals[intl.locale]
    && localizeRoute(router.query.canonicals[intl.locale], intl.locale);
  const resolvedHref = getPathWithoutSuffix(localizedHref) || normalizedHref;

  const isCurrentRouteActive = currentRoute === resolvedHref || canonicalRoute === resolvedHref;
  const hasActiveClassName = Boolean(activeClassName);

  const classNameWhenActive = className ? `${className} ${activeClassName}` : activeClassName;

  const componentProps = {
    ...rest,
    ref,
    hrefLang: locale !== intl.locale
      ? locale
      : undefined,
    className: hasActiveClassName && isCurrentRouteActive
      ? classNameWhenActive
      : className,
  };

  if (forceRefresh) {
    return (
      <Link {...componentProps} href={addSlashAtEnd(localizedHref)}>
        {children}
      </Link>
    );
  }

  return (
    <RouterLinkComponent
      href={href}
      as={localizedHref}
      prefetch={false}
      passHref
    >
      <Link {...componentProps}>
        {children}
      </Link>
    </RouterLinkComponent>
  );
});

RouterLink.defaultProps = {
  activeClassName: undefined,
  className: undefined,
  forceRefresh: false,
  locale: null,
  linkComponent: 'a',
  onClick: undefined,
  rel: undefined,
  routerLinkComponent: NextLink,
  target: undefined,
};

RouterLink.propTypes = {
  activeClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  forceRefresh: PropTypes.bool,
  href: PropTypes.string.isRequired,
  linkComponent: PropTypes.elementType,
  locale: PropTypes.string,
  onClick: PropTypes.func,
  rel: PropTypes.string,
  routerLinkComponent: PropTypes.elementType,
  target: PropTypes.string,
};

export default RouterLink;
