export const SEARCH_MVP = {
  id: 'DeJoywMWSNGPIExRYXJqaQ',
  name: 'Search MVP',
  variants: ['1'],
  variantA: '1',
};

export const PHONE_REMINDER = {
  id: 'TOJyxGmrTNmGlRdZ3nRGqw',
  name: 'Phone Reminder',
  variants: ['1', '2'],
  variantA: '1',
  variantB: '2',
};

export const REVAMP_RTR_SEC = {
  id: 'aq9vDF4OSoKRsEeR4bSC1g',
  name: 'Revamp Sec',
  variants: ['1'],
  variant: '1',
};

export const CREDIT_CARD_EXPERIMENT = {
  id: 'sP1hyP7ESYCxaSU4DfjsRA',
  name: 'Credit Card Experiment',
  variants: ['1'],
};

export const ACCOUNT_EXPERIMENT = {
  id: '8nFCFDQJTw2WM-K9cfr9Fw',
  name: 'Account Experiment',
  variants: ['1'],
};

export const REQUEST_REVAMP = {
  id: 'MKGZ3d0uQMiF4PLwWzXrYA',
  name: 'Request Revamp',
  variants: ['1'],
  variant: '1',
};
