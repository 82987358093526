import { v4 as uuid } from 'uuid';

const USER_SESSION_STORAGE_KEY = 'www-latam/userSession';

let storage = null;

/*
this is a simplistic and temporary way that we are using
to control the rollout of web journey metrics
*/
const webJourneyMetricsEnabled = () => {
  const rolloutEnabledFor = 1;
  return Math.random() < rolloutEnabledFor;
};

export const userSession = {
  initialize: clientStorage => {
    if (!clientStorage?.setItem || !clientStorage?.getItem) throw new Error('Invalid storage passed to userSession initialize');
    storage = clientStorage;

    const session = {
      id: uuid(),
      shouldSendWebJourneyMetrics: webJourneyMetricsEnabled(),
      initializedAt: Date.now(),
    };
    storage.setItem(
      USER_SESSION_STORAGE_KEY,
      JSON.stringify(session),
    );
  },
  getId: () => {
    if (!storage) throw new Error('UserSession was not initialized');
    const sessionStr = storage.getItem(USER_SESSION_STORAGE_KEY);
    const session = JSON.parse(sessionStr);
    return session && session.id;
  },
  get: attr => {
    if (!storage) throw new Error('UserSession was not initialized');
    const sessionStr = storage.getItem(USER_SESSION_STORAGE_KEY);
    const session = JSON.parse(sessionStr);
    return session && session[attr];
  },
};
